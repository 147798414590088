import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

import Header from './Header';

export default function Page(props) {
	const [changelog, setChangelog] = useState('Загрузка...');

	useEffect(() => {
		async function getChangelog() {
			const res = await fetch("https://multiadmin.staging.cubitel.org/files/CHANGELOG.md");
			const text = await res.text();
			setChangelog(text);
		}
		getChangelog();
	}, []);

	return (<>
		<Helmet>
			<title>Multiadmin -- changelog</title>
		</Helmet>
		<Header />
		<ReactMarkdown>{changelog}</ReactMarkdown>
	</>);
}
