import { Helmet } from 'react-helmet';
import * as MUI from '@mui/material';

import IconExpand from '@mui/icons-material/ExpandMore';

import Header from './Header';

export default function Page() {

	const atleastSmall = true;

	return (<>
		<Helmet>
			<title>Multiadmin -- ERP система для операторов связи</title>
		</Helmet>
		<MUI.Box sx={{ pb: 2 }}>
			<Header />

			<MUI.Stack spacing={2} direction={atleastSmall ? "row" : "column"}
				divider={<MUI.Divider orientation={atleastSmall ? "vertical" : "horizontal"} flexItem />}
			>
				<MUI.Box sx={{ width: 1 }}>
					<MUI.Typography variant="h6">
						О программе
					</MUI.Typography>
					<MUI.Typography sx={{ pb: 2 }}>
						В текущей версии программы реализован учёт сетевой инфраструктуры (кабелей, муфт), схемы разварок.
						В ближайших версиях будет доступна интергация с биллингом (карточка абонента).
						В числе первых будет доступна интергация с LanBilling и Hydra.
					</MUI.Typography>
					<MUI.Typography variant="h6">
						Стоимость
					</MUI.Typography>
					<MUI.Typography sx={{ pb: 2 }}>
						Доступ к дистрибутивам и обновлениям программы предоставляется при
						условии приобретения поддержки.
						Стоимость поддержки программы составляет 10 000 рублей в месяц (минимальный пакет -- один месяц).
						Для заключения договора отправьте ваши реквизиты на почту npk@cubitel.org.
						Мы работаем только с юридическими лицами.
					</MUI.Typography>
				</MUI.Box>
				<MUI.Box sx={{ width: 1 }}>
				<MUI.Typography variant="h6">
						Дорожная карта
					</MUI.Typography>
					<MUI.Typography sx={{ pb: 2 }}>
						Реализованный и планируемый (серым цветом) функционал.
					</MUI.Typography>
					<MUI.Accordion>
						<MUI.AccordionSummary expandIcon={<IconExpand />}>
							Интеграция с биллингом
						</MUI.AccordionSummary>
						<MUI.AccordionDetails>
							<MUI.List dense={true}>
								<MUI.ListItem>
									<MUI.ListItemText primary="Поддержка авторизации через биллинг" secondary="Все изменения от имени конкретного сотрудника" />
								</MUI.ListItem>
							</MUI.List>
						</MUI.AccordionDetails>
					</MUI.Accordion>
					<MUI.Accordion>
						<MUI.AccordionSummary expandIcon={<IconExpand />}>
							Сетевая инфраструктура
						</MUI.AccordionSummary>
						<MUI.AccordionDetails>
							<MUI.List dense={true}>
								<MUI.ListItem>
									<MUI.ListItemText primary="Полная история изменений" secondary="Состояние на любой момент времени"/>
								</MUI.ListItem>
								<MUI.ListItem>
									<MUI.ListItemText primary="Карта сети" secondary="С трассами кабелей"/>
								</MUI.ListItem>
								<MUI.ListItem>
									<MUI.ListItemText primary="Сварки и коммутации" secondary="Полная схема сети" />
								</MUI.ListItem>
								<MUI.ListItem>
									<MUI.ListItemText primary="Импорт из сторонних программ" secondary="UserSide" />
								</MUI.ListItem>
							</MUI.List>
						</MUI.AccordionDetails>
					</MUI.Accordion>
					<MUI.Accordion>
						<MUI.AccordionSummary expandIcon={<IconExpand />}>
							Управление сетью
						</MUI.AccordionSummary>
						<MUI.AccordionDetails>
							<MUI.List dense={true}>
								<MUI.ListItem disabled={true}>
									<MUI.ListItemText primary="Управление сетевыми ресурсами" secondary="IP адреса, VLAN" />
								</MUI.ListItem>
								<MUI.ListItem disabled={true}>
									<MUI.ListItemText primary="Управление оборудованием" secondary="VLAN, PON, MPLS" />
								</MUI.ListItem>
								<MUI.ListItem disabled={true}>
									<MUI.ListItemText primary="Intent-based Networking" secondary="В идеале" />
								</MUI.ListItem>
							</MUI.List>
						</MUI.AccordionDetails>
					</MUI.Accordion>
				</MUI.Box>
			</MUI.Stack>
		</MUI.Box>
	</>);
}
