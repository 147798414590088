import { Link } from 'react-router-dom';
import * as MUI from '@mui/material';

import IconDemo from '@mui/icons-material/Balance';
import IconHome from '@mui/icons-material/Home';
import IconInstall from '@mui/icons-material/GetApp';
import IconChangelog from '@mui/icons-material/ChangeHistory';

const MenuItem = (props) => (
	<MUI.ListItem button component={Link} to={props.href}>
		<MUI.ListItemIcon sx={{minWidth: '40px'}}>{props.icon}</MUI.ListItemIcon>
		<MUI.ListItemText primary={props.name} secondary={props.descr} />
	</MUI.ListItem>
)

export default function Header() {
	return (<>
		<MUI.Grid container spacing={{ xs: 2 }} columns={{ xs: 1, md: 2, lg: 4 }} sx={{ mb: 2 }}>
			<MUI.Grid item xs={1}>
				<MenuItem name="MultiAdmin" descr="ERP-система для операторов связи"
					icon={<IconHome />}
					href="/multiadmin"
				/>
			</MUI.Grid>
			<MUI.Grid item xs={1}>
				<MenuItem name="Демонстрационная версия" descr="admin / admin"
					icon={<IconDemo />}
					href="https://ma-demo.cubitel.org/"
				/>
			</MUI.Grid>
			<MUI.Grid item xs={1}>
				<MenuItem name="Установка" descr="Инструкция по установке"
					icon={<IconInstall />}
					href="/multiadmin/setup"
				/>
			</MUI.Grid>
			<MUI.Grid item xs={1}>
				<MenuItem name="ChangeLog" descr="Стадия: Pre-Alpha"
					icon={<IconChangelog />}
					href="/multiadmin/changelog"
				/>
			</MUI.Grid>
		</MUI.Grid>
	</>);
}
