import { Routes, Route, Navigate } from "react-router-dom";

import Main from "./Main";
import Changelog from './Changelog';
import Setup from "./Setup";

export default function Billing() {
	return(<Routes>
		<Route path="/" element={(<Main />)} />
		<Route path="/changelog" element={(<Changelog />)} />
		<Route path="/setup" element={(<Setup />)} />
		<Route path="*" element={<Navigate to="/" replace />} />
	</Routes>);
};
