import { Routes, Route, Navigate } from "react-router-dom";

import Info from "./Info";

export default function Billing() {
	return(<Routes>
		<Route path="/info" element={(<Info />)} />
		<Route path="*" element={<Navigate to="/" replace />} />
	</Routes>);
};
