import { Link, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import * as MUI from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import MainPage from './MainPage';
import Company from './company';
import Multiadmin from './multiadmin';

const theme = createTheme({
	palette: {
		secondary: {
			main: '#343a40',
		},
	},
});

export default function App({ Component, pageProps }) {

	return (<>
		<ThemeProvider theme={theme}>
		<Helmet titleTemplate="НПК Кубител :: %s">
			<title>?</title>
		</Helmet>
		<MUI.Box sx={{ display: 'flex' }}>
			<MUI.CssBaseline />
			<MUI.AppBar position="fixed" color="secondary">
				<MUI.Toolbar>
					<MUI.Typography variant="h6" noWrap component={Link} to="/" sx={{
						color: 'inherit',
						textDecoration: 'none'
					}}>
						НПК Кубител
					</MUI.Typography>
				</MUI.Toolbar>
			</MUI.AppBar>
			<MUI.Box component="main" sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh' }}
			>
				<MUI.Toolbar />
				<MUI.Box sx={{ flexGrow: 1, p: 2 }}>
					<Routes>
						<Route exact path="/" element={<MainPage />} />
						<Route path="/company/*" element={<Company />} />
						<Route path="/multiadmin/*" element={<Multiadmin />} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</MUI.Box>
				<MUI.Box sx={{ px: 1, pb: 1 }}>
					<MUI.Divider sx={{ mb: 1 }}/>
					<MUI.Typography variant="body2">
						АО НПК &quot;Кубител&quot; (ЗАО &quot;Кубител Юг&quot;). Тел: +7 861 211-30-03. E-mail: npk@cubitel.org.
						[<Link to="/company/info">Все реквизиты</Link>]
					</MUI.Typography>
					<MUI.Typography variant="body2">350033 г. Краснодар, ул. Железнодорожная, д. 31 оф. 33</MUI.Typography>
				</MUI.Box>
			</MUI.Box>
		</MUI.Box>
		</ThemeProvider>

	</>);
}
