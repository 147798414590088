import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as MUI from '@mui/material';

export default function MainPage() {
	return (<>
		<Helmet>
			<title>Главная</title>
		</Helmet>
		<MUI.Grid container spacing={{ xs: 2 }} columns={{ xs: 1, md: 2 }} sx={{ mb: 2 }}>

			<MUI.Grid item xs={1}>
				<MUI.Card sx={{ width: 1, borderLeft: 4, borderLeftColor: '#00f' }}>
					<MUI.CardContent>
						<MUI.Typography variant="h5" component="div">
							MultiAdmin
						</MUI.Typography>
						<MUI.Typography>
							ERP-система для операторов связи.
							Учёт сетевой инфраструктуры (трассы кабелей, сварки).
						</MUI.Typography>
					</MUI.CardContent>
					<MUI.CardActions>
						<MUI.Button component={Link} to="/multiadmin">Подробнее</MUI.Button>
					</MUI.CardActions>
				</MUI.Card>
			</MUI.Grid>

			<MUI.Grid item xs={1}>
				<MUI.Card sx={{ width: 1, borderLeft: 4, borderLeftColor: '#a00' }}>
					<MUI.CardContent>
						<MUI.Typography variant="h5" component="div">
							Кубител Аэро
						</MUI.Typography>
						<MUI.Typography>
							Строительство и эксплуатация авиатренажеров.
						</MUI.Typography>
					</MUI.CardContent>
					<MUI.CardActions>
						<MUI.Button component={Link} to="https://cubitel.aero/">Перейти на сайт</MUI.Button>
					</MUI.CardActions>
				</MUI.Card>
			</MUI.Grid>

		</MUI.Grid>
	</>);
}
