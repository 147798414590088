import { Helmet } from 'react-helmet';
import * as MUI from '@mui/material';

import Header from './Header';

function CodeBox(props) {
    return (
        <pre style={{
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '4px'
        }}>
            <code>{props.children}</code>
        </pre>
    );
}

export default function Page() {
	return (<>
		<Helmet>
			<title>Multiadmin -- Установка</title>
		</Helmet>
		<Header />

        <MUI.Tabs value="docker-compose">
            <MUI.Tab value="docker-compose" label="docker-compose" />
        </MUI.Tabs>
        <MUI.Divider />

        <MUI.Box sx={{ p: 2 }}>
            <MUI.Typography>
                Программа распространяется в виде docker образов.
                Далее будет рассматриваться вариант установки с помощью docker-compose.
                Для доступа к дистрибутиву нужно получить логин и пароль к репозиторию  у автора программы.
            </MUI.Typography>
            <CodeBox>
                docker login cr.cubitel.org
            </CodeBox>

            <MUI.Typography>
                Создайте произвольную директорию. Имя директории будет использоваться docker-compose для
                выбора имен контейнеров и директорий с данными.
                Переименовать директорию в дальнейшем будет нельзя.
                Скачайте файл docker-compose.yml.
            </MUI.Typography>
            <CodeBox>
                mkdir multiadmin && cd multiadmin<br/>
                wget https://ma-demo.cubitel.org/files/docker-compose.yml
            </CodeBox>

            <MUI.Typography>
                Запустите контейнеры.
            </MUI.Typography>
            <CodeBox>
                docker-compose up
            </CodeBox>

            <MUI.Typography>
                После запуска программа будет доступна по адресу http://localhost:3000/.
                Рекомендуется настроить обратный прокси (например nginx) с поддержкой TLS (некоторые функции работают только по протоколу https) и HTTP/2
                (для ускорения загрузки).
                Дальнейшая настройка производится из веб-интерфейса программы.
            </MUI.Typography>
        </MUI.Box>
	</>);
}
