import { Helmet } from 'react-helmet';
import * as MUI from '@mui/material';

export default function MainPage() {
	return (<>
		<Helmet>
			<title>Информация о компании</title>
		</Helmet>
		<MUI.Grid container spacing={{ xs: 2 }} columns={{ xs: 1, md: 2 }} sx={{ mb: 2 }}>

			<MUI.Grid item xs={1}>
				<MUI.Typography variant="h5">Контактная информация</MUI.Typography>
				<MUI.Typography>Телефон: +7 861 211-30-03</MUI.Typography>
				<MUI.Typography>E-Mail: npk@cubitel.org</MUI.Typography>
				<MUI.Typography>Адрес: 350033 г. Краснодар, ул. Железнодорожная, д. 31, оф. 33</MUI.Typography>
			</MUI.Grid>

			<MUI.Grid item xs={1}>
				<MUI.Typography variant="h5">Реквизиты</MUI.Typography>
				<MUI.Typography>ЗАО &quot;Кубител Юг&quot;</MUI.Typography>
				<MUI.Typography>ОГРН: 1132308016125, ИНН: 2308205251, КПП: 230801001</MUI.Typography>
				<MUI.Typography>Юр. адрес: 350089, г. Краснодар, б-р Клары Лучко, д. 10</MUI.Typography>
				<MUI.Typography>Р/с: 40702810226020001507</MUI.Typography>
				<MUI.Typography>Филиал &quot;Ростовский&quot; АО &quot;АЛЬФА-БАНК&quot;, БИК 046015207, К/с 30101810500000000207</MUI.Typography>
			</MUI.Grid>

		</MUI.Grid>
	</>);
}
